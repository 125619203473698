import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import "../css/FindUs.scss"

const FindUs = () => {
    const date = new Date();
    const month = date.getMonth();

    const summerSchedule = {
        title: 'Horario de verano (Julio y Agosto)',
        days: 'Lunes a Viernes',
        hours: '08:00 - 15:00'
    };

    const winterSchedule = {
        title: 'Horario',
        days: 'Lunes a Viernes',
        hours: 'Mañanas 09:00 - 14:00\nTardes 16:00 - 19:00'
    };

    const currentSchedule = (month === 6 || month === 7) ? summerSchedule : winterSchedule;

    return (
        <div className="flex flex-col mt-10">
            <div id="google-maps"
                className="__MobileLocationButton text-white p-3 flex items-center justify-center w-24 h-24 mb-6 shadow-lg rounded-full bg-sonsurAccent-light hover:bg-sonsurAccent-dark flex-center"
                onClick={() => {
                    window.open("https://maps.apple.com/?q=SONSUR&sll=40.3967897,-3.696513&z=10&t=s");
                }}
            >
                <FontAwesomeIcon icon={faMapMarkedAlt} size='2x' className="center-icon" />
            </div>
            <div className="w-full px-4 mr-auto ml-auto md:space-y-10">
                <h2 className="tracking-wider mb-4 font-bold text-3xl text-center text-sonsurAccent-dark">
                    Donde puedes encontrarnos
                </h2>
                <div className="divider div-transparent_find_us"></div>
                <div className="md:grid lg:grid-cols-7 md:grid-cols-3 space-y-10 mt-10 items-baseline">
                    <div className="lg:col-start-2">
                        <h4 className="text-center text-xl font-bold text-sonsurAccent-dark">
                            Dirección
                        </h4>
                        <p className="text-center text-md font-light mt-2 text-sonsurAccent-dark">
                            Calle de Embajadores, nº 150, local. 28045 Madrid
                        </p>
                        <p className="text-center text-md font-light mt-2 text-sonsurAccent-dark">
                            Metro: Delicias <a className="text-lg font-extrabold" style={{ color: '#ffd700', textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white' }}>(Linea 3)</a>,
                            Legazpi <a className="text-lg font-extrabold" style={{ color: '#948d8d', textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white' }}>(Linea 6)</a>
                        </p>
                        <p className="text-center text-md font-light mt-2 text-sonsurAccent-dark">
                            AutoBuses: 59, 85, 45, 6, 78, 148, 47
                        </p>
                    </div>
                    <div className="lg:col-start-4">
                        <h4 className="text-center text-xl font-bold text-sonsurAccent-dark">
                            {currentSchedule.title}
                        </h4>
                        <div className="text-center text-md font-light mt-2 text-sonsurAccent-dark">
                            <p className="mb-2"><b>{currentSchedule.days}</b></p>
                            {currentSchedule.hours.split('\n').map((line, index) => (
                                <p key={index}>
                                    {line.includes('Mañanas') || line.includes('Tardes')
                                        ? line.split(' ').map((word, wordIndex) => (
                                            (word === 'Mañanas' || word === 'Tardes')
                                                ? <b className="font-bold" key={wordIndex}>{word} </b>
                                                : word + ' '
                                        ))
                                        : line}
                                </p>
                            ))}
                            {/* <div className="text-sonsurAccent-dark mt-4 p-3 rounded-lg shadow-lg bg-sonsurGray-lighter">
                                <p className="font-bold">Próximo 3 de Mayo permaneceremos cerrados</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="lg:col-start-6">
                        <h4 className="text-center text-xl font-bold text-sonsurAccent-dark">
                            Teléfonos
                        </h4>
                        <p className="text-center text-md font-light mt-2 text-sonsurAccent-dark">
                            <a href="tel:+34914748251">
                                91 474 82 51
                            </a>
                        </p>
                        <p className="text-center text-md font-light mt-2 text-sonsurAccent-dark">
                            <a href="tel:+34914748316">
                                91 474 83 16
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindUs;

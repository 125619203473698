import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


const SEO = ({ title, lang, description, keywords }) => {
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    siteUrl,
    defaultDescription,
    defaultKeywords,
    defaultLang,
} = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: siteUrl,
    keywords: keywords || defaultKeywords,
    lang: lang || defaultLang,
  }
  return (
    <Helmet htmlAttributes={{lang}} title={seo.title} >
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.keywords && <meta property="keywords" content={seo.keywords} />}
    </Helmet>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  keywords: PropTypes.string,
  lang: PropTypes.string
}
SEO.defaultProps = {
  title: `SONSUR Servicio Tecnico Oficial Sony y Panasonic`,
  url: "https://www.sonsur.com",
  description: `Servicio Técnico Oficial de las marcas SONY y PANASONIC. Gestión de su garantía, reparación de equipos, repuestos y accesorios originales.`,
  author: `@sonsur`,
  keywords: `sony, panasonic, servicio tecnico oficial sony madrid, servicio tecnico oficial panasonic madrid, servicio tecnico embajadores, reparacion camaras, camaras, accesorios, garantia sony, garantia panasonic, reparacion television, reparacion, reparacion profesional, camaras profesionales, servicio tecnico profesional sony, servicio tecnico profesional panasonic, venice reparaciones, venice servicio, venice servicio tecnico oficial, reparacion tv panasonic, reparacion tv`,
  lang: `es`
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        url
        description
        keywords
      }
    }
  }
`
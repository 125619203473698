import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ServicesData from '../data/services-data';


const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery{
      images: allFile(filter: {relativeDirectory: {eq: "cards"}}) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(   
              quality: 100, 
              transformOptions: {fit: CONTAIN, cropFocus: ATTENTION},
              placeholder: DOMINANT_COLOR,
              webpOptions: {quality: 100},
              layout: FULL_WIDTH,
              blurredOptions: {toFormat: PNG}
              )
          }
        }
      }
    }
  `)
  return (
    <div className="md:my-40 md:space-y-10 mt-20">
      <h2 className="font-bold text-3xl text-center text-sonsurAccent-dark">Nuestros servicios</h2>
      <div className="divider div-transparent_find_us"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {ServicesData.map((service) => (
          <div key={service.id} className="transform hover:scale-105 transition-transform duration-200 ease-in-out w-full max-w-md mx-auto bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
            <div className="relative">
              <GatsbyImage image={getImage(data.images.nodes.find(element => element.name === service.name).childImageSharp)} alt={service.alt} />
              <div className="absolute inset-0 bg-black bg-opacity-25"></div>
            </div>
            <div className="text-center absolute bottom-0 bg-gradient-to-t from-black to-transparent text-white p-4">
              <h2 className="text-2xl font-bold">{service.title}</h2>
              <p className="text-gray-200 mt-1">{service.content}</p>
              {service.title === 'Cine digital Venice' && <Link to="/venice/" className='mt-4 block w-full px-4 py-2 text-center text-white bg-blue-500 hover:bg-blue-600 rounded-md transition-colors duration-200'>Ver</Link>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services;

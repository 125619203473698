import React from 'react';

const PanasonicApprovedService = () => (
<svg width="100%" height="100%" viewBox="0 0 251.19 121.7">
      <defs>
        <style>
          {
            ".cls-1{fill:none}.cls-2{clip-path:url(#clip-path)}.cls-3{fill:#3157a3}.cls-7{clip-path:url(#clip-path-4)}.cls-8{fill:#fff}"
          }
        </style>
        <clipPath id="clip-path">
          <path className="cls-1" d="M0 83.14H253.85V121.7H0z" />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            className="cls-1"
            d="M89.14 8.65H165.14V20.189999999999998H89.14z"
          />
        </clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Panasonic">
          <g id="g3344">
            <g className="cls-2" id="g3532">
              <g id="g3534">
                <g id="g3540">
                  <path
                    id="path3542"
                    className="cls-3"
                    d="M248.43 117.33a13.14 13.14 0 01-7.2 4 17.62 17.62 0 01-5.61.27 13.24 13.24 0 01-6.88-2.68 14.26 14.26 0 01-4-4.54 12.48 12.48 0 01-1.59-4.42 14.76 14.76 0 01.32-6.51 13.23 13.23 0 0110.31-9.72 17.72 17.72 0 018.44.2 11.66 11.66 0 015.26 2.89 10.47 10.47 0 012.31 3 8.12 8.12 0 011 4.35h-9.16a4.5 4.5 0 00-1.77-3 3.72 3.72 0 00-4.3-.21 4.21 4.21 0 00-1.88 2.42 12.37 12.37 0 00-.13 7.35 5.59 5.59 0 001.61 2.66 3.82 3.82 0 003.31 1 3 3 0 001.93-1 3.88 3.88 0 001-2.06 12.77 12.77 0 00.19-1.62h9.65a9.68 9.68 0 01-2.73 7.58"
                  />
                </g>
              </g>
            </g>
            <path
              id="path3544"
              className="cls-3"
              d="M211.52 90.39h10v-7.07h-10z"
            />
            <path
              id="path3546"
              className="cls-3"
              d="M211.52 120.8h10V94h-10z"
            />
            <g id="g3548">
              <path
                id="path3550"
                className="cls-3"
                d="M190.78 96.85a16.79 16.79 0 014.16-2.7 10.89 10.89 0 017.91-.59 8.65 8.65 0 016 5.84 13.08 13.08 0 01.63 4.12v17.28h-10.65v-15.54a4.54 4.54 0 00-.22-1.46 2.93 2.93 0 00-1.72-1.91 4 4 0 00-5.06 1.67 4.31 4.31 0 00-.63 2.32v14.92h-10.3V94h9.66l.22 2.83"
              />
            </g>
            <g id="g3552">
              <path
                id="path3554"
                className="cls-3"
                d="M72.35 96.85a17 17 0 014.16-2.7 10.89 10.89 0 017.91-.59 8.65 8.65 0 016 5.84 13.09 13.09 0 01.64 4.12v17.28H80.4v-15.54a4.54 4.54 0 00-.22-1.46 2.93 2.93 0 00-1.72-1.91 4 4 0 00-5.06 1.67 4.31 4.31 0 00-.63 2.32v14.92h-10.3V94h9.66l.22 2.83"
              />
            </g>
            <g className="cls-2" id="g3556">
              <g id="g3558">
                <g id="g3564">
                  <path
                    id="path3566"
                    className="cls-3"
                    d="M135.47 111a14.54 14.54 0 012.14.53 1.71 1.71 0 011.17 1.31 1.93 1.93 0 01-.54 1.84 3 3 0 01-1.78.91 5 5 0 01-2.68-.25 3.59 3.59 0 01-1.14-.65 2.89 2.89 0 01-.93-1.4 4.34 4.34 0 01-.2-1.43h-9.81v.57a6.88 6.88 0 00.47 2.57 8.16 8.16 0 002.18 3.25 10.42 10.42 0 004.46 2.51 23.7 23.7 0 0010.62.67 14.86 14.86 0 004.76-1.55 9.43 9.43 0 001.81-1.28 8.14 8.14 0 002.57-8.91 6.54 6.54 0 00-2-3 11.41 11.41 0 00-4.44-2.42l-1.07-.3a48.16 48.16 0 00-6.07-1.29c-.57-.08-1.06-.18-1.63-.31a3.94 3.94 0 01-.73-.25 1.19 1.19 0 01-.59-1.58v-.09a2.39 2.39 0 011.44-1.15 5.23 5.23 0 013.25.08 2.45 2.45 0 011.67 2.44h9.45a9.36 9.36 0 00-.58-3.23 5.77 5.77 0 00-1.93-2.68 9.2 9.2 0 00-2.53-1.44 13.57 13.57 0 00-2.74-.76 28 28 0 00-6.72-.38 21.56 21.56 0 00-3.83.55 11.47 11.47 0 00-4.66 2.24 7.44 7.44 0 00-2.54 3.56 6.2 6.2 0 00-.38 2 7.58 7.58 0 00.55 3.26 7.14 7.14 0 004.12 4.13 17.39 17.39 0 002.91.87c2.06.42 3.81.75 5.89 1.11"
                  />
                </g>
              </g>
            </g>
            <g id="g3568">
              <path
                id="path3570"
                className="cls-3"
                d="M18.25 109.25h-6.87v-8.78h4.46c.85 0 1.57 0 2.41-.07a3.69 3.69 0 002.86-1.53 3.38 3.38 0 00.66-1.63 5.8 5.8 0 000-1.5A3.78 3.78 0 0018 92.32h-6.62v28.48H0V83.31h18.59c.74 0 1.37 0 2.11.06A13.13 13.13 0 0133 98a12.16 12.16 0 01-9.65 10.74 22.66 22.66 0 01-5.06.54"
              />
            </g>
            <g id="g3572">
              <path
                id="path3574"
                className="cls-3"
                d="M167.58 93.51a19.25 19.25 0 00-6.06 0 13.9 13.9 0 00-7.81 3.87 12.75 12.75 0 00-4 8.36 15.34 15.34 0 00.52 5.76 13.21 13.21 0 004.91 7.26 12.72 12.72 0 005.29 2.46 19.7 19.7 0 008.28 0 12.72 12.72 0 005.29-2.46l-5.75-6.27a4 4 0 01-5.33 2 3.94 3.94 0 01-2-2 9.24 9.24 0 01-.76-2.58 15 15 0 01.12-5.38 7 7 0 011.05-2.59 3.92 3.92 0 015.41-1.13 3.8 3.8 0 011.13 1.13 7 7 0 011.05 2.59 15.2 15.2 0 01.12 5.38 9.24 9.24 0 01-.76 2.58l5.75 6.27a13.21 13.21 0 004.91-7.26 15.34 15.34 0 00.52-5.76 12.73 12.73 0 00-4-8.36 14 14 0 00-7.82-3.87"
              />
            </g>
            <g className="cls-2" id="g3576">
              <g id="g3578">
                <g id="g3584">
                  <path
                    id="path3586"
                    className="cls-3"
                    d="M120.6 118.7c-.15-2.2-.23-4.07-.28-6.29 0-2.9-.09-5.36-.11-8.26a16.92 16.92 0 00-.58-4.42 7 7 0 00-3.47-4.51 11.75 11.75 0 00-3.3-1.24 28.15 28.15 0 00-11.16-.22 13.24 13.24 0 00-3 .88 8.05 8.05 0 00-4.77 5.22 6.28 6.28 0 00-.25 2.76 1.38 1.38 0 00.14.49l9 .22a4 4 0 01.25-1.6 2.71 2.71 0 011.7-1.7 5.21 5.21 0 013.59-.06 2.43 2.43 0 011.63 1.65 1.58 1.58 0 01-.23 1.44 2.1 2.1 0 01-1 .8 6.3 6.3 0 01-.77.25 33.93 33.93 0 01-3.6.78c-1 .17-1.86.33-2.87.53a31.55 31.55 0 00-3.82 1 8 8 0 00-3.1 1.81 6.55 6.55 0 00-2.15 4.06 8.4 8.4 0 00.13 3.12 7.46 7.46 0 005.32 5.66 13 13 0 008.85-.45 8.47 8.47 0 003.51-2.56l-.63-4.61a4 4 0 01-1.78 1.52 6.64 6.64 0 01-2.14.56 4.58 4.58 0 01-1.84-.16 2.63 2.63 0 01-1.27-.76 2.2 2.2 0 01-.58-1.12 1.8 1.8 0 01.61-1.78 3.46 3.46 0 011.13-.66c1-.38 1.94-.67 3-1a23.06 23.06 0 003.59-1.32 10.42 10.42 0 010 2.79 4.47 4.47 0 01-.71 1.91l.63 4.61c.2.65.38 1.2.61 1.84a2.56 2.56 0 00.54.91h9.92a4.26 4.26 0 01-.69-2.11"
                  />
                </g>
                <g id="g3588">
                  <path
                    id="path3590"
                    className="cls-3"
                    d="M60.49 118.7c-.15-2.2-.24-4.07-.28-6.29-.06-2.9-.1-5.36-.12-8.26a17.35 17.35 0 00-.57-4.42A7 7 0 0056 95.22 11.69 11.69 0 0052.75 94a28.15 28.15 0 00-11.16-.22 13.32 13.32 0 00-3 .88 8 8 0 00-4.76 5.22 6.43 6.43 0 00-.26 2.76 1.22 1.22 0 00.15.49l9 .22a4 4 0 01.24-1.6 2.76 2.76 0 011.7-1.7 5.24 5.24 0 013.6-.06 2.45 2.45 0 011.63 1.65 1.64 1.64 0 01-.23 1.44 2.2 2.2 0 01-1 .8 7.08 7.08 0 01-.78.25 33.93 33.93 0 01-3.6.78c-1 .17-1.86.33-2.87.53a31.55 31.55 0 00-3.82 1 8 8 0 00-3.1 1.81 6.54 6.54 0 00-2.14 4.06 8.19 8.19 0 00.12 3.12 7.46 7.46 0 005.32 5.66 13 13 0 008.86-.45 8.6 8.6 0 003.51-2.56l-.64-4.61a3.93 3.93 0 01-1.83 1.53 6.57 6.57 0 01-2.14.56 4.52 4.52 0 01-1.83-.16 2.63 2.63 0 01-1.27-.76 2.3 2.3 0 01-.59-1.12 1.8 1.8 0 01.61-1.78 3.57 3.57 0 011.13-.66c1-.38 1.94-.67 3-1a22.69 22.69 0 003.59-1.32 10 10 0 010 2.79 4.47 4.47 0 01-.71 1.91l.64 4.61c.19.65.38 1.2.6 1.84a2.71 2.71 0 00.54.91h9.93a4.17 4.17 0 01-.69-2.11"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Panasonic_Approved_Service"
          data-name="Panasonic Approved Service"
        >
          <path d="M83.2 0H170.66V82.97H83.2z" />
          <text
            transform="translate(94.18 70.67)"
            fontSize="8px"
            fill="#959595"
            fontFamily="Trebuchet MS Bold"
            fontWeight={700}
            letterSpacing="-.05em"
          >
            {"APPROVED SE"}
            <tspan x={46.28} y={0} letterSpacing="-.09em">
              {"R"}
            </tspan>
            <tspan x={50.48} y={0}>
              {"VICE"}
            </tspan>
          </text>
          <g id="g3344-2" data-name="g3344">
            <g className="cls-7" id="g3532-2" data-name="g3532">
              <g id="g3534-2" data-name="g3534">
                <g id="g3540-2" data-name="g3540">
                  <path
                    id="path3542-2"
                    data-name="path3542"
                    className="cls-8"
                    d="M163.52 18.89a4 4 0 01-2.16 1.19 5.26 5.26 0 01-1.68.08 3.9 3.9 0 01-2.06-.81 4.21 4.21 0 01-1.2-1.35 3.73 3.73 0 01-.48-1.33 4.43 4.43 0 01.1-2 4 4 0 013.08-2.91 5.28 5.28 0 012.53.07 3.54 3.54 0 012.27 1.76 2.47 2.47 0 01.28 1.41h-2.74a1.38 1.38 0 00-.53-.89 1.13 1.13 0 00-1.29-.06 1.26 1.26 0 00-.56.72 3.7 3.7 0 000 2.21 1.71 1.71 0 00.48.79 1.14 1.14 0 001 .31.9.9 0 00.58-.31 1.17 1.17 0 00.3-.62 3.7 3.7 0 00.06-.48h2.89a2.93 2.93 0 01-.82 2.27"
                  />
                </g>
              </g>
            </g>
            <path
              id="path3544-2"
              data-name="path3544"
              className="cls-8"
              d="M152.47 10.82h3V8.71h-3z"
            />
            <path
              id="path3546-2"
              data-name="path3546"
              className="cls-8"
              d="M152.47 19.93h3v-8h-3z"
            />
            <g id="g3548-2" data-name="g3548">
              <path
                id="path3550-2"
                data-name="path3550"
                className="cls-8"
                d="M146.26 12.76a4.71 4.71 0 011.25-.81 3.21 3.21 0 012.36-.18 2.6 2.6 0 011.79 1.75 3.68 3.68 0 01.19 1.23v5.18h-3.18v-4.66a1.29 1.29 0 00-.07-.43.83.83 0 00-.51-.57 1.2 1.2 0 00-1.52.5 1.28 1.28 0 00-.19.69v4.47h-3.08v-8h2.89l.07.85"
              />
            </g>
            <g id="g3552-2" data-name="g3552">
              <path
                id="path3554-2"
                data-name="path3554"
                className="cls-8"
                d="M110.8 12.76a4.87 4.87 0 011.25-.81 3.24 3.24 0 012.37-.18 2.6 2.6 0 011.79 1.75 4 4 0 01.19 1.23v5.18h-3.19v-4.66a1.59 1.59 0 00-.06-.43.85.85 0 00-.52-.57 1.19 1.19 0 00-1.51.5 1.28 1.28 0 00-.19.69v4.47h-3.09v-8h2.9l.06.85"
              />
            </g>
            <g className="cls-7" id="g3556-2" data-name="g3556">
              <g id="g3558-2" data-name="g3558">
                <g id="g3564-2" data-name="g3564">
                  <path
                    id="path3566-2"
                    data-name="path3566"
                    className="cls-8"
                    d="M129.7 17a4.61 4.61 0 01.64.16.5.5 0 01.35.39.56.56 0 01-.16.55.92.92 0 01-.53.28 1.59 1.59 0 01-.81-.08 1.14 1.14 0 01-.34-.19 1 1 0 01-.28-.42 1.59 1.59 0 01-.06-.43h-2.93v.17a2 2 0 00.14.77 2.38 2.38 0 00.65 1 3.12 3.12 0 001.34.75 7.08 7.08 0 003.17.2 4.44 4.44 0 001.43-.46 2.94 2.94 0 00.55-.37 2.43 2.43 0 00.77-2.67 1.94 1.94 0 00-.6-.91 3.45 3.45 0 00-1.33-.74l-.32-.1a14 14 0 00-1.82-.38l-.49-.1a.86.86 0 01-.21-.07.36.36 0 01-.18-.47.7.7 0 01.43-.34 1.53 1.53 0 011 0 .72.72 0 01.5.73h2.83a2.68 2.68 0 00-.17-1 1.71 1.71 0 00-.58-.8 2.81 2.81 0 00-.75-.43 4 4 0 00-.82-.23 8.54 8.54 0 00-2-.11 6.26 6.26 0 00-1.14.16 3.45 3.45 0 00-1.4.68 2.14 2.14 0 00-.76 1.06 2 2 0 00-.11.59 2.14 2.14 0 00.16 1 2.16 2.16 0 001.24 1.24 4.87 4.87 0 00.87.26c.61.13 1.14.22 1.76.33"
                  />
                </g>
              </g>
            </g>
            <g id="g3568-2" data-name="g3568">
              <path
                id="path3570-2"
                data-name="path3570"
                className="cls-8"
                d="M94.61 16.47h-2.06v-2.63h2.06a1.11 1.11 0 00.85-.46 1 1 0 00.2-.49 1.62 1.62 0 000-.44 1.13 1.13 0 00-1.13-1h-2v8.53h-3.39V8.7h6.2a3.8 3.8 0 013.32 2.3 3.92 3.92 0 01.34 2.09 3.64 3.64 0 01-2.89 3.22 7 7 0 01-1.51.16"
              />
            </g>
            <g id="g3572-2" data-name="g3572">
              <path
                id="path3574-2"
                data-name="path3574"
                className="cls-8"
                d="M139.31 11.76a5.37 5.37 0 00-.9-.07 5.58 5.58 0 00-.91.07 4.11 4.11 0 00-2.34 1.16 3.8 3.8 0 00-1.2 2.5 4.54 4.54 0 00.15 1.72 4 4 0 001.47 2.18 3.85 3.85 0 001.59.73 5.56 5.56 0 002.48 0 3.87 3.87 0 001.58-.73l-1.72-1.88a1.22 1.22 0 01-1.6.61 1.15 1.15 0 01-.6-.61 2.63 2.63 0 01-.23-.77 4.36 4.36 0 010-1.61 2.22 2.22 0 01.32-.78 1.15 1.15 0 011.95 0 2 2 0 01.32.78 4.36 4.36 0 010 1.61 3 3 0 01-.22.77l1.72 1.88a3.92 3.92 0 001.47-2.18 4.75 4.75 0 00.16-1.72 3.82 3.82 0 00-1.21-2.5 4.08 4.08 0 00-2.34-1.16"
              />
            </g>
            <g className="cls-7" id="g3576-2" data-name="g3576">
              <g id="g3578-2" data-name="g3578">
                <g id="g3584-2" data-name="g3584">
                  <path
                    id="path3586-2"
                    data-name="path3586"
                    className="cls-8"
                    d="M125.25 19.3c0-.66-.07-1.22-.08-1.88v-2.48a4.93 4.93 0 00-.17-1.32 2.09 2.09 0 00-1-1.35 3.48 3.48 0 00-1-.37 8.35 8.35 0 00-3.34-.07 3.9 3.9 0 00-.9.27 2.39 2.39 0 00-1.43 1.56 1.9 1.9 0 00-.08.82.38.38 0 000 .15l2.69.07a1.31 1.31 0 01.08-.48.81.81 0 01.51-.51 1.57 1.57 0 011.07 0 .74.74 0 01.49.5.47.47 0 01-.07.43.67.67 0 01-.31.24l-.23.07c-.37.1-.69.17-1.08.24l-.86.15a9.59 9.59 0 00-1.14.3 2.55 2.55 0 00-.93.54 2 2 0 00-.64 1.22 2.42 2.42 0 000 .93 2.24 2.24 0 001.59 1.7 3.9 3.9 0 002.65-.14 2.5 2.5 0 001-.76l-.07-1.4a1.11 1.11 0 01-.53.45 1.84 1.84 0 01-.64.17 1.28 1.28 0 01-.55-.05.75.75 0 01-.38-.23.67.67 0 01-.18-.33.54.54 0 01.19-.53 1 1 0 01.33-.2c.32-.12.58-.2.91-.29a7.3 7.3 0 001.07-.4 3 3 0 010 .84 1.31 1.31 0 01-.21.57l.19 1.38.18.55a.92.92 0 00.16.27h3a1.19 1.19 0 01-.21-.63"
                  />
                </g>
                <g id="g3588-2" data-name="g3588">
                  <path
                    id="path3590-2"
                    data-name="path3590"
                    className="cls-8"
                    d="M107.25 19.3c0-.66-.07-1.22-.08-1.88v-2.48a4.93 4.93 0 00-.17-1.32 2.06 2.06 0 00-1-1.35 3.42 3.42 0 00-1-.37 8.4 8.4 0 00-3.35-.07 4.22 4.22 0 00-.9.27 2.39 2.39 0 00-1.43 1.56 1.9 1.9 0 00-.08.82.38.38 0 000 .15l2.7.07a1.12 1.12 0 01.07-.48.81.81 0 01.51-.51 1.57 1.57 0 011.07 0 .71.71 0 01.49.5.45.45 0 01-.07.43.64.64 0 01-.3.24l-.24.07c-.37.1-.69.17-1.07.24l-.86.15a9.26 9.26 0 00-1.15.3 2.55 2.55 0 00-.93.54 2 2 0 00-.64 1.22 2.64 2.64 0 000 .93 2.24 2.24 0 001.59 1.7 3.89 3.89 0 002.65-.14 2.5 2.5 0 001-.76l-.06-1.4a1.11 1.11 0 01-.53.45 1.79 1.79 0 01-.64.17 1.29 1.29 0 01-.55-.05.75.75 0 01-.38-.23.59.59 0 01-.17-.33.51.51 0 01.18-.53 1.05 1.05 0 01.34-.2 9.43 9.43 0 01.9-.29 6.53 6.53 0 001.07-.4 3 3 0 010 .84 1.33 1.33 0 01-.22.57l.19 1.38c.06.19.12.36.19.55a.63.63 0 00.16.27h3a1.28 1.28 0 01-.21-.63"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
);

export default PanasonicApprovedService;

import React, { useState } from 'react';
import SonsurLogoBig from '../svg/SonsurLogoBig'


const Contacto = ({ title, message }) => {
  
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const mail = "sonsur@sonsur.com";
  
  function handleSubmit(event) {
    event.preventDefault();
    window.location.href = `mailto:${mail}?subject=${subject}&body=${body}`;
    setSubject('');
    setBody('');
  }

  return (
  <div className="text-black ld:py-20">
    <div className="flex flex-col lg:flex-row my-6 lg:my-24">
      <div className="flex flex-col p-8 lg:space-y-5 space-y-2">
        <p className="font-bold text-lg my-4 leading-relaxed md:leading-snug">{title}</p>
        <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
          {message}
        </p>
        <figure>
          <SonsurLogoBig />
        </figure>
      </div>
      <div className="flex flex-col w-full justify-center">
        <div className="md:px-auto mx-auto">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-sonsurGray-lighter">
                <div className="flex-auto p-5 lg:p-10">
                  <h4 className="text-2xl mb-4 text-black font-semibold">Formulario</h4>
                  <form id="feedbackForm" onSubmit={handleSubmit}>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="subject">Asunto</label><input type="text" name="subject" id="subject" className="border-0 px-3 py-3 rounded text-sm shadow w-full
                bg-gray-300 placeholder-black text-gray-800 outline-none focus:bg-gray-400" placeholder="Escribe aquí tu asunto"
                          style={{ transition: "all 0.15s ease 0s" }} required onChange={event => setSubject(event.target.value)} value={subject}/>
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message">Mensaje</label><textarea maxLength="300" name="feedback" id="feedback" rows="6"
                          cols="80"
                          className="border-0 px-3 py-3 bg-gray-300 placeholder-black text-gray-800 rounded text-sm shadow focus:outline-none w-full"
                          placeholder="Escribe aquí una breve descripción de tu problema" required onChange={event => setBody(event.target.value)} value={body}></textarea>
                    </div>
                    <div className="text-center mt-6">
                      <button id="feedbackBtn"
                        className="bg-sonsurAccent-dark text-white text-center mx-auto active:bg-sonsurAccent-dark text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="submit" style={{ transition: "all 0.15s ease 0s" }}>Enviar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
  };

export default Contacto;
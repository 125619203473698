import React, { useRef } from 'react';
import Layout from '../components/layout/Layout';
import HeaderIntro from '../components/layout/intro';
import Contacto from '../components/contacto'
import SEO from '../components/seo';
import Services from '../components/Services';
import FindUs from '../components/FindUs';
import AboutUs from '../components/AboutUs';
import SvgLogos from '../components/SvgLogos';


const Index = () => {
  return (
    <Layout>
      <SEO lang="es" />
      <section id="header">
        <HeaderIntro />
      </section>
      <section id="about">
        <AboutUs/>
      </section>
      <section id="findus">
        <FindUs/>
      </section>
      <section id="services">
        <Services/>
      </section>
      <section id="contact" className="w-full bg-gray-200 rounded-lg text-center">
        <Contacto title="Si necesitas especialistas para gestionar tu garantía o solucionar cualquier problema de tu dispositivo Sony o Panasonic, podemos ayudarte" message="Nuestro departamento de atención al cliente se pondrá en contacto contigo a la mayor brevedad posible" />
      </section>
      <section id="SvgLogos">
        <SvgLogos/>
      </section>
    </Layout>
  )
}

export default Index;

import React from 'react';
import PanasonicApprovedService from '../svg/PanasonicApprovedService'
import SonyApprovedService from '../svg/SonyApprovedService'

const SvgLogos = () => (
      <div className="container mx-auto items-center flex flex-col lg:flex-row">
        <figure className="mx-auto lg:py-10 my-10">
          <SonyApprovedService />
        </figure>
        <figure className="mx-auto my-10">
          <PanasonicApprovedService />
        </figure>
        </div>
);

export default SvgLogos;

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from '../css/AboutUs.module.scss'

const AboutUs = () => {

    const { backImage } = useStaticQuery(graphql`
    query {
        backImage: file(relativePath: {eq: "Location.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH,
            placeholder: BLURRED,
            webpOptions: {quality: 100},
            blurredOptions: {toFormat: PNG}
            )
        }
      }
    }
        `)

    const pluginImage = getImage(backImage)
    return (
        <div className="flex flex-col lg:flex-row p-4 md:p-20 md:m-auto mt-10 mx-auto">
            <div className="self-center w-full lg:w-1/2">
                <div className="md:p-10 p-4">
                    <p className="tracking-wider mb-4 font-bold text-3xl text-center text-sonsurAccent-dark">
                        Sobre nosotros
                    </p>
                    <div className="divider div-transparent_about_us"></div>
                    <p className="text-sonsurAccent-dark text-lg font-bold text-center">
                        Gestione la garantía de su equipo con la total seguridad y confianza.
                    </p>
                    <p className="text-sonsurAccent-dark text-lg font-bold text-center">
                        Nos avalan más de 30 años como Servicio Técnico Oficial.
                    </p>
                    <ul className="list-disc text-sonsurAccent-dark text-base mt-6 font-thin text-center"style={{ listStylePosition: 'inside' }}>
                        <li>Ofrecemos la reparación de los equipos <b className="font-bold">Sony</b> y <b className="font-bold">Panasonic</b>.</li>
                        <li>Repuestos y accesorios <b className="font-bold">originales</b>.</li>
                        <li>Contratos de mantenimiento, instalaciones audiovisuales y otros servicios audiovisuales.</li>
                    </ul>
                </div>
            </div>
            <div className={`${styles.thumbnail} lg:block hidden w-1/2`}>
                <a href="https://maps.apple.com/?q=SONSUR&sll=40.3967897,-3.696513&z=10&t=s" target="_blank" rel="noopener noreferrer">
                    <GatsbyImage image={pluginImage} alt="Location" className={`${styles.gatsbyImage}`} />
                </a>
                <a href="https://maps.google.com/?q=SONSUR" target="_blank" rel="noopener noreferrer"><span>Abrir en google maps</span></a>
            </div>
        </div>
    )
};

export default AboutUs;

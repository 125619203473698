export default [
  {
    title: 'Garantias',
    name:  'garantia',
    id: 1,
    content:
      'Servicio de gestión de la garantía de su equipo doméstico y profesional con la total seguridad y confianza que nos diferencia como Servicio Técnico Oficial.',
    alt: 'Garantias',
  },
  {
    title: 'Broadcast & profesional',
    name:  'broadcast',
    id: 2,
    content:
      'Contamos con los mejores medios técnicos y humanos para asegurarle el mejor resultado.',
    alt: 'Broadcast & profesionals',
  },
  {
    title: 'Reparación',
    name:  'reparacion',
    id: 3,
    content:
      'Garantía completa de los componentes requeridos en todas las reparaciones. Nuestras máximas son la eficiencia, rapidez y calidad en la atención al cliente.',
    alt: 'Reparación',
  }
  ,
  {
    title: 'Cine digital Venice',
    name:  'venice',
    id: 4,
    content:
      'SONSUR ya es partner de Servicio Técnico oficial certificado para VENICE',
    alt: 'Cine digital Venice',
  },
  {
    title: 'Technics',
    name:  'technics',
    id: 5,
    content:
      'Servicio de reparación y soporte de equipos Technics',
    alt: 'Technics',
  }
];

import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import "../../css/intro.scss";

const HeaderIntro = () => {

    const { backImage } = useStaticQuery(graphql`
        query {
            backImage: file(relativePath: {eq: "Background.png"}) {
            id
            childImageSharp {
              gatsbyImageData(
                quality: 100, 
                placeholder: BLURRED,
                webpOptions: {quality: 100},
                blurredOptions: {toFormat: PNG}
                )
            }
          }
        }
        `
    )
    const pluginImage = getImage(backImage)

    return (
        <>
            <BgImage image={pluginImage} key={backImage.id}>
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
                    <div className="flex flex-col md:pt-20 md:pb-40 py-10 md:px-40 px-10">
                        <div className="text-center text-white text-3xl lg:text-5xl xl:text-9xl font-bold lg:text-left">
                            <p>SONSUR servicio técnico oficial Sony y Panasonic</p>
                            <div className="divider div-transparent"></div>
                        </div>
                        <div>
                            <p className="lg:text-left text-center font-light text-white text-md __steeMobile">
                                Servicios Técnicos Electrónicos Embajadores S.L.
                            </p>
                        </div>
                        <div><p className="lg:text-left text-center text-white text-base lg:text-2xl md:mt-20 font-thin md:mb-20 mb-10">
                            Servicio Técnico Oficial de las marcas <b className="lg:text-3xl">Sony® Panasonic</b>® desde 1982.
                        </p>
                        </div>
                        <a href="#services" className="lg:self-start self-center">
                            <button className="uppercase md:py-4 md:px-6 text-white custom-btn btn-10 text-base lg:mr-auto">Consulta nuestros servicios</button>
                        </a>
                    </div>
                </div>
            </BgImage>
        </>
    )
}

export default HeaderIntro;
